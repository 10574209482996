import React, { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledH1 } from '../components/_shared/styled-headings';
import { StyledFullHeightSection } from '../components/_shared/styled-section';

import Icon from '../components/icon';


import Contract from '../assets/icons/contract.svg';
import Invoice from '../assets/icons/invoice.svg';


const Row = styled.div`
  display: flex;
  gap: 1rem;

  margin-bottom: 10vh;

  /* ${mq.gt.sm} {
    grid-template-columns: 20% 80%;
  } */
`;

const SVGIcon = styled.span`
  fill: white;

  svg {
    width: 10vw;
    height: 10vh;
  }

  & .color {
    fill: var(--primary-color);
  }
` 


const Thanks = () => {

  
  return (
    <Layout menuLinks={blogMenuLinks}>
    <SEO title="Thanks" />

      <StyledFullHeightSection>

        <StyledH1>Thank you for your submission!</StyledH1>

        <h2>What&apos;s next?</h2>

        <Row>
          <SVGIcon>
            <Contract />
          </SVGIcon>
          <p>An agreement will be sent to you via the email you submitted, for you to digitally sign.</p>
        </Row>

        <Row>
          <SVGIcon>
            <Invoice />
          </SVGIcon>
          <p>Also attached will be your first invoice. Work will begin once the initial invoice is paid.</p>
        </Row>
        
        <Row style={{textAlign: "center"}}>
          <Icon icon="smile" />
          <p>Thank you again. The developer will be in touch if there are any questions about your website.</p>
        </Row>

      </StyledFullHeightSection>


    </Layout>
  );
};

export default Thanks;
